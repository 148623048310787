import React, { createContext, useState } from "react";
import { Header, Modal } from "semantic-ui-react";

import ReservationStep1 from "./Steps/ReservationStep1";
import ReservationStep2 from "./Steps/ReservationStep2";
import ReservationStep3 from "./Steps/ReservationStep3";

const STEPS = [
  { title: "차량정보조회", detail: "고객님의 차량정보를 확인합니다." },
  { title: "차량시세조회", detail: "고객님의 차량시세를 확인합니다." },
  { title: "성능점검예약", detail: "차량 판매를 위한 성능점검 일정을 예약합니다." },
];
export const ReservationContext = createContext(null);

export default function ReservationPopup({ isOpened, doClose }) {
  const [reservationData, setReservationData] = useState({
    step1: {},
    step2: {},
    step3: {},
  });

  const [currentStep, setCurrentStep] = useState(1);
  const backToPrevStep = () => {
    currentStep > 1 && setCurrentStep((prevState) => prevState - 1);
  };
  const goToNextStep = () => {
    currentStep < 5 && setCurrentStep((prevState) => prevState + 1);
  };
  const storeData = (step, data) => {
    setReservationData((prevState) => ({ ...prevState, [step]: data }));
  };

  return (
    <Modal
      closeIcon
      className={"reservation-popup"}
      open={isOpened}
      size={"small"}
      // dimmer={'inverted'}
      centered={true}
      closeOnDimmerClick={false}
      onClose={doClose}
    >
      <Modal.Header>
        <Header style={{ margin: 0 }} as={"h3"}>
          <Header.Content>카몬 서비스 예약</Header.Content>
          <Header.Subheader>내 차 시세 알아보고, 정비소 방문해 보세요!</Header.Subheader>
        </Header>
      </Modal.Header>
      <Modal.Content id={"reservation-popup-content"} scrolling>
        <ReservationContext.Provider
          value={{
            allSteps: STEPS,
            currentStep,
            backToPrevStep,
            goToNextStep,
            storeData,
          }}
        >
          <ReservationStep1 />
          <ReservationStep2 />
          <ReservationStep3 />
          {/*<ReservationStep4 />*/}
          {/*<ReservationStep5 />*/}
        </ReservationContext.Provider>
      </Modal.Content>
      {/*<Modal.Actions>*/}
      {/*  <Button id={'prev-step'} onClick={backToPrevStep}>*/}
      {/*    <Icon name="chevron left" /> 이전*/}
      {/*  </Button>*/}
      {/*  <Button id={'next-step'} color="yellow" onClick={goToNextStep}>*/}
      {/*    다음 <Icon name="chevron right" />*/}
      {/*  </Button>*/}
      {/*</Modal.Actions>*/}
    </Modal>
  );
}

import React, {useContext, useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  Divider, Form,
  Grid,
  Header,
  Icon, Input,
  Item,
  Label,
  List,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';
import _ from 'lodash';

import ReservationStepOuter from '../ReservationStepOuter';
import axios from 'axios';
import {CommonUtil} from '../../../../utils/CommonUtil';
import moment from 'moment';
import ReservationContext from '../../../../stores/ReservationContext';
import {useNavigate} from 'react-router-dom';
import {BASE_STYLES} from '../../../../Consts';

const MY_STEP = 1;

export default function ReservationStep1({style: outerStyle}) {
  const {goToNextStep, startLoading, finishLoading} = useContext(ReservationContext);
  const navigate = useNavigate();

  const [toNext, setToNext] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step1Params, setStep1Params] = useState({
    licensePlateNumber: '',
  });
  const [step1ParamsError, setStep1ParamsError] = useState({
    licensePlateNumber: false,
  });
  const [step1Data, setStep1Data] = useState(null);

  let timer = null;
  const checkCarExists = () => {
    timer = setTimeout(() => startLoading(), 3000);
    setIsLoading(true);

    axios.post('/apis/reservation/step1', {
      licensePlateNumber: step1Params.licensePlateNumber,
    })
      .then(({data}) => setStep1Data(data))
      .catch(({response}) => {
        console.error(response);
      })
      .finally(() => {
        if (timer !== null) {
          clearTimeout(timer);
          finishLoading();
          timer = null;
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    setToNext(step1Params.licensePlateNumber !== '' && !step1ParamsError.licensePlateNumber);
  }, [step1Params.licensePlateNumber]);

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
  }, []);

  return (
    <ReservationStepOuter myStep={MY_STEP} isLoading={isLoading}>
      <Header as={'h3'}>
        <Header.Content>
          차량번호를 입력하세요!
          <Header.Subheader>
            차량번호와 소유자 이름만 입력하시면, 내 차 정보와 시세를 확인하실 수 있습니다!
          </Header.Subheader>
        </Header.Content>
      </Header>

      <Grid stackable>
        <Grid.Row columns={2} centered stretched>
          <Grid.Column width={12}>
            <Form>
              <Form.Field
                control={Input}
                fluid
                placeholder="차량번호를 정확히 입력해주세요."
                maxLength={11}
                style={{imeMode: 'inactive', fontSize: BASE_STYLES.FONT_SIZE.SUB}}
                value={step1Params.licensePlateNumber}
                onChange={(e, {value}) => {
                  const trimmed = value.replace(/\s/g, '');
                  const regExp = /[0-9ㄱ-ㅎㅏ-ㅣ가-힣ㆍᆢ]+$/;
                  setStep1ParamsError(prevState => ({
                    ...prevState,
                    licensePlateNumber: !CommonUtil.Validator.validateLicensePlateNumber(trimmed),
                  }));
                  setStep1Params(prevState => ({...prevState, licensePlateNumber: trimmed}));
                }}
                icon={(step1Params.licensePlateNumber !== '') &&
                <Icon name="remove" link onClick={() => setStep1Params(prevState => ({
                  ...prevState,
                  licensePlateNumber: '',
                }))} />}
                error={(step1Params.licensePlateNumber !== '' && step1ParamsError.licensePlateNumber) && {
                  content: '올바른 차량번호를 입력하세요.',
                  pointing: 'above',
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter' && step1Params.licensePlateNumber !== '') {
                    e.target.blur();
                    checkCarExists();
                  }
                }} />
            </Form>
          </Grid.Column>
          <Grid.Column width={4}>
            <Button
              size={'large'}
              fluid
              disabled={!toNext}
              content={'조회'}
              icon={'search'}
              color={'yellow'}
              onClick={() => checkCarExists()} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {
              (step1Data !== null) &&
              (
                _.get(step1Data, 'resultCode') === 'OK'
                  ?
                  (
                    <>
                      <Message info icon>
                        <Icon name="car" />
                        <Message.Content>
                          <Message.Header>고객님의 차량이 조회되었습니다!</Message.Header>
                          고객님의 [{_.get(step1Data, 'licensePlateNumber')}]
                          차량은 <b>{_.get(step1Data, 'data.prodYear')}년식 {_.get(step1Data, 'data.carMaker')} {_.get(step1Data, 'data.carName')}</b> 차량으로
                          확인되었습니다.
                        </Message.Content>
                      </Message>
                    </>
                  )
                  :
                  (
                    _.get(step1Data, 'count') === 0
                      ?
                      (
                        <Message warning icon>
                          <Icon name="question" />
                          <Message.Content>
                            <Message.Header>차량정보가 확인되지 않았습니다. 아래와 같은 이유인지 확인해보세요.</Message.Header>
                            <List bulleted>
                              <List.Item>차량번호가 정확히 입력되었는지 확인해주세요.</List.Item>
                              <List.Item>차량정보를 확인하기 위해 차량 소유자의 이름이 필요합니다. 다음 단계로 이동하세요.</List.Item>
                            </List>
                          </Message.Content>
                        </Message>
                      )
                      :
                      (
                        <Message warning icon>
                          <Icon name="question" />
                          <Message.Content>
                            <Message.Header>차량정보의 추가 확인이 필요합니다.</Message.Header>
                            차량 모델을 명확하게 확인하지 못했습니다.
                          </Message.Content>
                        </Message>
                      )
                  )
              )
            }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Message>
              <Message.Content>
                <Message.Header>
                  <Icon name="info circle" /> 차량이 확인되지 않았나요? 아래와 같은 사유일 수 있습니다.
                </Message.Header>
                <List bulleted>
                  <List.Item>유효한 차량번호가 아닌 경우</List.Item>
                  <List.Item>차량번호는 맞는데 입력이 잘못된 경우(오타, 공백문자 등)</List.Item>
                  <List.Item>차량의 상세 모델 확인이 필요한 경우</List.Item>
                  <List.Item>정확한 차량 확인을 위해 차량 소유자명이 필요한 경우</List.Item>
                </List>
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign={'right'}>
            <Button
              disabled={_.isEmpty(step1Data)}
              icon={'undo'} content={'다시하기'} onClick={() => {
              setStep1Params({licensePlateNumber: ''});
              setStep1Data(null);
            }} />
            <Button
              color={'teal'}
              disabled={_.isEmpty(step1Data)}
              onClick={() => {
                goToNextStep({
                  params: step1Params,
                  step1Data,
                });
              }}>
              다음 <Icon name={'angle right'} fitted />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ReservationStepOuter>
  );
}
